import { render, staticRenderFns } from "./CursosEcodrive.vue?vue&type=template&id=52dea670&scoped=true&"
import script from "./CursosEcodrive.vue?vue&type=script&lang=js&"
export * from "./CursosEcodrive.vue?vue&type=script&lang=js&"
import style0 from "./CursosEcodrive.vue?vue&type=style&index=0&id=52dea670&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52dea670",
  null
  
)

export default component.exports