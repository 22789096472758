import axios from 'axios';
import store from '../store';

export default function setup() {
  let solicitudes = 0;

  axios.interceptors.request.use(function(config) {
    solicitudes++;

    store.commit("actualizarLoading", true);

    return config;
  }, function(err) {
    return Promise.reject(err);
  });

  axios.interceptors.response.use(function(response) {
    solicitudes--;
    
    if(!solicitudes) {
      store.commit("actualizarLoading", false);
    }
    return response.data;
  }, function(err) {
    return Promise.reject(err);
  });
}