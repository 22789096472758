<template>
  <div>
    <b-card-body>
      <!-- agregar nueva euro -->
      <b-row>
        <b-col>
          <h5 class="text-left">{{titulo}}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Nombre" v-model="euroSeleccionado.descripcion"></b-form-input>
          </b-input-group>
        </b-col>
        
        <b-col md="12">
          <b-input-group class="mb-5 mt-3" style="justify-content: flex-end;">
            <b-button size="sm" variant="outline-danger" @click="limpiarEuro()" class="mr-1">
              Limpiar
            </b-button>
            <b-button size="sm" @click="guardarEuro()" class="mr-1">
              Guardar
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
      
      <b-table
        small
        stacked="md"
        :items="listaEuros"
        :fields="fields"
        :head-variant="'dark'"
        class="tabla-euros"
      >
        <template #cell(fecha)="row">
          {{ row.item.fecha | moment("ll") }}
        </template>
        <template #cell(accion)="row">
          <b-button
              size="sm"
              @click="editarEuro(row.item, row.index, $event.target)"
              class="mr-1 btn-editar"
              variant="light"
            >
            <i class="fas fa-pencil-alt"></i>
          </b-button>
          <b-button
            size="sm"
            @click="modalEliminarEuro(row.item, row.index, $event.target)"
            variant="light"
            class="btn-eliminar"
          >
            <i class="fas fa-trash-alt"></i>
          </b-button>
        </template>
      </b-table>
      <div v-if="!listaEuros.length">No hay registros para mostrar</div>
    </b-card-body>
    <b-modal id="bv-modal-advertencia" hide-header>
      <div class="d-block text-center">
        <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
        <h6>¿Esta seguro que desea eliminar el registro?</h6>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="eliminarEuro()">
          Aceptar
        </b-button>
      </template>
    </b-modal>

    <b-toast id="toast-euros" :variant="variantToast" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong>{{textToast}}</strong>
        </div>
      </template>
    </b-toast>
  </div>
</template>

<script>
import services from "@/services";
export default {
  name: "Euros",
  data() {
    return {
      titulo: "Agregar Euro",
      listaEuros: [],
      euroSeleccionado: {},
      variantToast: "",
      textToast: "",
      fields: [
        {
          key: "descripcion",
          label: "Nombre",
        },

        { key: "accion", label: "Acciones" },
      ]
    };
  },
  created() {
    this.obtenerlistaEuros();
  },
  methods: {
    guardarEuro() {
      if(!this.euroSeleccionado.descripcion){
        this.mostrarToast("warning", "El nombre es requerido.");
        return;
      }

      if(this.euroSeleccionado.id) {
        this.guardarActualizarEuro("EditarNorma");
      } else {
        this.guardarActualizarEuro("GuardarNorma");
      }
      
    },
    guardarActualizarEuro(servicio) {
      let self = this;
      services[servicio](self.euroSeleccionado)
      .then((response) => {
        if (response.IsValid) {
          self.obtenerlistaEuros();
          self.euroSeleccionado = {};
          self.titulo = "Agregar Euro";
          self.mostrarToast("success", response.Message);
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    editarEuro(euro) {
      this.titulo = "Editar Euro";
      this.euroSeleccionado = {...euro};
    },
    obtenerlistaEuros() {
      let self = this;
      services.ObtenerListaNormas({
      })
      .then((response) => {
        if (response.IsValid) {
          self.listaEuros = response.Data;
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    modalEliminarEuro(euro) {
      this.euroEliminar = {...euro};
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminarEuro() {
      let self = this;
      services.EliminarNorma({
        id: self.euroEliminar.id
      })
      .then((response) => {        
        this.$bvModal.hide('bv-modal-advertencia');
        if (response.IsValid) {
          self.euroEliminar = {};
          self.mostrarToast("success", response.Message);
          self.obtenerlistaEuros();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    limpiarEuro() {
      this.titulo = "Agregar Euro"
      this.euroSeleccionado = {};
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-euros');
    }
  },
};
</script>

<style scoped>
  .btn-editar { color: #007bff; }
  .btn-eliminar { color: #dc3545; }
</style>
