<template>
  <b-container style="padding-top: calc(50vh - 160px);">
    <b-card bg-variant="light" class="text-center mt-0 container-form mx-auto">
      <b-card-body>
        <b-img center :src="require('@/assets/images/logo-hino.png')" alt="Logo Hino"></b-img>
      </b-card-body>
      <b-card-body>
        <b-form>
          <b-form-input
            id="email"
            v-model="usuario.email"
            type="email"
            required
            placeholder="Correo"
          ></b-form-input>

          <b-form-input
              id="password"
              type="password"
              class="mt-2"
              v-model="usuario.password"
              required
              placeholder="Contraseña"
          ></b-form-input>
          <div class="mt-4">
            <b-link href="#" @click="$bvModal.show('cambioContraseña')">Olvidé mi contraseña</b-link>
          </div>
          <b-button type="button" class="mt-2" variant="primary" @click="iniciar()">Iniciar Sesión</b-button>
        </b-form>
      </b-card-body>
    </b-card>

    <b-modal id="cambioContraseña" size="md" title="Recuperar contraseña">
      <div class="text-center">
        Ingresa tu correo electrónico y te enviaremos tu nueva contraseña.
      </div>
      <div class="text-center mb-3">
        Podras cambiarla despues desde el apartado de configuración.
      </div>
      <b-form>
        <b-input-group>
          <b-form-input placeholder="Correo" v-model="emailRecuperacion"></b-form-input>
        </b-input-group>
      </b-form>

      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('cambioContraseña')">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="recuperarContraseña()">
          Recuperar contraseña
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modalInformativo" size="md" title="Correo electrónico enviado">
      <div class="text-center">
        Revisa tu bandeja de entrada, te enviamos una nueva contraseña.
      </div>
      
      <template #modal-footer>
        <b-button size="sm" variant="primary"  @click="$bvModal.hide('modalInformativo')">
          Aceptar
        </b-button>
      </template>
    </b-modal>

    <b-toast id="my-toast" :variant="variantToast" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong>{{textToast}}</strong>
        </div>
      </template>
    </b-toast>
  </b-container>
</template>

<script>
import services from "@/services";
export default {
  name: 'Login',
  data() {
    return {
      usuario: {},
      variantToast: "",
      textToast: "",
      emailRecuperacion: ""
    }
  },
  created() {

  }, 
  methods: {
    iniciar() {
      let self = this;
      if(!self.usuario.email) {
        self.mostrarToast("warning", "El correo es requerido.")
        return;
      }
      if(!self.validateEmail(self.usuario.email)) {
        self.mostrarToast("warning", "El formato de correo no es valido.")
        return;
      }
      if(!self.usuario.password) {
        self.mostrarToast("warning", "La contraseña es requerida.")
        return;
      }

      services.login(self.usuario)
      .then(response => {
        if(response.IsValid) {
          self.$store.dispatch("iniciarSesion", response.Data);
          self.$router.push({name: 'banner'});
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });      
    },
    validateEmail(email) {
      var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      return re.test(email.toLowerCase());
    },
    recuperarContraseña() {
      let self = this;
      if(!self.emailRecuperacion) {
        self.mostrarToast("warning", "El correo es requerido.")
        return;
      }
      if(!self.validateEmail(self.emailRecuperacion)) {
        self.mostrarToast("warning", "El formato de correo no es valido.")
        return;
      }

      services.olvidasteContrasena({
        email: self.emailRecuperacion
      })
      .then(response => {
        self.emailRecuperacion = "";
        if(response.IsValid) {
          self.$bvModal.hide('cambioContraseña');
          self.$bvModal.show('modalInformativo');
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('my-toast');
    }
  }
}
</script>

<style scoped>
  .container-form { width: 400px; }
  .contenedor-general { margin-left: 0; width: 100%; }
  .b-sidebar, .hino-navbar { display: none; }
</style>
