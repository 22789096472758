<template>
  <div>
    <b-card-body>
      <!-- agregar nueva imagen -->
      <b-row>
        <b-col md="12" sm="12">
          <b-form-file
            class="mt-2"
            v-model="archivos"
            placeholder="Seleccionar o arrastrar imagen"
            drop-placeholder="Suelta la imagen aquí..."
            ref="file-input" 
            :change="getBase64()"
            accept=".jpeg, .jpg, .png"
            multiple
          ></b-form-file>
        </b-col>
        <b-col md="12" sm="12" v-if="archivos.length">
          <table class="table table-bordered mt-4">
            <thead class="thead-dark">
              <tr>
                <th colspan="2">Imagenes a guardar</th>
              </tr>
            </thead>
            <tr v-for="(imagen, index) in archivos" :key="index">
              <td class="text-left">
                {{imagen.name}}
              </td>
              <td class="pl-4">                
                <b-button
                  :id="'ver-imagen'+index"
                  size="sm"
                  @click="verImagenGuardar(index)"
                  variant="light"
                  class="btn-editar ml-2"
                >
                  <i class="far fa-file-image"></i>
                </b-button>
                <b-tooltip :target="'ver-imagen'+index">Ver imagen</b-tooltip>

                <b-button
                  :id="'imagen'+index"
                  size="sm"
                  @click="eliminarImagenGuardar(index)"
                  variant="light"
                  class="btn-eliminar"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
                <b-tooltip :target="'imagen'+index">Eliminar</b-tooltip>
              </td>
            </tr>
          </table>
        </b-col>

        <b-col md="12">
          <b-input-group class="mb-5 mt-3">
            <b-button size="sm" @click="guardarImagen()" class="mr-1 ml-auto">
              Guardar
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Tabla con lista de imagenes -->
      <b-table
        small
        stacked="md"
        :items="listaImagenes"
        :fields="fields"
        :head-variant="'dark'"
      >
        <template #cell(accion)="row">
          <b-button
            :id="'ver-imagen'+row.index"
            size="sm"
            @click="verImagenGuardar(null, row.item.url_imagen)"
            variant="light"
            class="btn-editar ml-2"
          >
            <i class="far fa-file-image"></i>
          </b-button>
          <b-tooltip :target="'ver-imagen'+row.index">Ver imagen</b-tooltip>
          <b-button
            :id="'imagen'+row.index"
            size="sm"
            @click="modalEliminarImagen(row.item, row.index, $event.target)"
            variant="light"
            class="btn-eliminar"
          >
            <i class="fas fa-trash-alt"></i>
          </b-button>
          <b-tooltip :target="'imagen'+row.index">Eliminar</b-tooltip>
        </template>
      </b-table>
      <div v-if="!listaImagenes.length">No hay registros para mostrar</div>
    </b-card-body>
    <b-modal id="bv-modal-ver-imagen" hide-header>
      <div class="d-block text-center">
        <b-img center :src="urlImagenSeleccionada" width="450" alt="Responsive image"></b-img>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-ver-imagen')">
          Cerrar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="bv-modal-advertencia" hide-header>
      <div class="d-block text-center">
        <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
        <h6>¿Esta seguro que desea eliminar el registro?</h6>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="eliminarImagen()">
          Aceptar
        </b-button>
      </template>
    </b-modal>

    <b-toast id="toast-imagenes" :variant="variantToast" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong>{{textToast}}</strong>
        </div>
      </template>
    </b-toast>
  </div>
</template>

<script>
import services from "@/services";
let config = require("@/config")
export default {
  name: "imagenesModelo",
  props: ['idModelo'],
  data() {
    return {
      imagen: null,
      listaImagenes: [],
      archivos: [],
      listaArchivos: [],
      variantToast: "",
      textToast: "",
      urlImagenSeleccionada: null,
      fields: [
        {
          key: "nombre",
          label: "nombre",
        },
        { key: "accion", label: "Acciones" },
      ]
    };
  },
  created() {
    this.obtenerListaImagenes();
  },
  computed: {},
  mounted() {},
  methods: {
    getBase64() {
      let self = this;
      const tamaniomax = 2; //2mb
      const formato = ".jpeg, .jpg, .png";
      let lista = []
      if(self.archivos.length) {
        let readers = [];
        for (var i = 0; i < self.archivos.length; i++) {
          var file = self.archivos[i];
          // SI VIENE ALGO
          var extension = file.type.split('/')[1];
          var tamanioArchivo = file.size;
          var tamanioMax = tamaniomax * 1000000;

          // VALIDAR NOMBRE ARCHIVO
          var patt = new RegExp(/^[A-Za-z0-9\s.,-_ ]+$/g);

          if(!patt.test(file.name)) {
            self.archivos = [];
            this.$refs['file-input'].reset();
            self.mostrarToast("warning", "Revise que el nombre de la imagen no tenga caracteres especiales.");
            return
          }

          //SI SE PIDEN UN FORMATO ESPECIFICO
          var formatos = formato ? formato.split(',') : [];

          //SI SOLO SE ADMITE UN FORMATO Y NO ES CORRECTO
          if (formatos.length == 1 && `.${extension}` !== formato) {
            self.archivos = [];
            this.$refs['file-input'].reset();
            self.mostrarToast("warning", `El formato permitido es ${formato.replace(/[.]/g,"")}`);
            return
          } else if (formatos.length > 1) {
            //SI SE ADMITEN VARIOS FORMATOS
            var formatoValido;
            formatos.filter(formato => {
              if (`.${extension}` === formato.trim()) {
                return formatoValido = true;
              }
            })

            //SI EL FORMATO DEL ARCHIVO NO COINSIDE CON LOS PERMITIDOS
            if (!formatoValido) {
              self.archivos = [];
              this.$refs['file-input'].reset();
              self.mostrarToast("warning", `Los formatos permitidos son ${formato.replace(/[.]/g,"")}`);
              return
            }
          }

          //SI TAMAÑO MAYOR A TAMAÑO MAXIMO
          if (tamanioArchivo > tamanioMax) {
            self.archivos = [];
            this.$refs['file-input'].reset();
            self.mostrarToast("warning", `El peso máximo por archivo es de ${tamaniomax}MB`);
            return
          }


          readers[i] = new FileReader();
          readers[i].index = i;
          readers[i].onload = function (loadEvent) {
            var index = loadEvent.target.index;
            var obj = {
              tipoArchivo: self.archivos[index].type,
              archivo: loadEvent.target.result,
              nombreArchivo: self.archivos[index].name.substring(0, self.archivos[index].name.lastIndexOf('.')),
              tamanio: String(self.archivos[index].size),
              extension: `.${self.archivos[index].name.split('.').pop()}`
            }
            lista.push(obj)
          };
          readers[i].readAsDataURL(self.archivos[i]);
        }
      }

      self.listaArchivos = lista;
    },
    eliminarImagenGuardar(indice) {
      this.archivos.splice(indice, 1)
    },
    verImagenGuardar(indice, url) {
      if(indice >= 0 && indice != null) {
        this.urlImagenSeleccionada = this.listaArchivos[indice].archivo;
      } else if(url) {
        this.urlImagenSeleccionada = url;
      }
      this.$bvModal.show('bv-modal-ver-imagen')
    },
    guardarImagen() {
      let self = this;
      if(self.listaArchivos.length) {
        self.listaArchivos.forEach((item, index) => {
          self.servicioGuardarImagen(item, index+1);
        });
      } else {
        self.mostrarToast("warning", "No hay imagenes para guardar.");
      }      
    },
    servicioGuardarImagen(parametros, indice) {
      let self = this;
      services.GuardarImagenesVehiculo({
        id_vehiculo: self.idModelo,
        imagen_base64: parametros.archivo,
        nom_imagen: parametros.nombreArchivo,
        ext_imagen: parametros.extension
      })
      .then((response) => {
        if (response.IsValid) {

          if(indice == self.listaArchivos.length) {
            let mensaje = self.listaArchivos.length > 1 ? "Imagenes guardadas correctamente." : "Imagen guardada correctamente.";
            self.mostrarToast("success", mensaje);

            self.archivos = [];
            self.listaArchivos = [];
            self.urlImagenSeleccionada = null;

            self.obtenerListaImagenes();
          }          
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      })
    },
    obtenerListaImagenes() {
      let self = this;
      services.ObtenerImagenesVehiculoPorId({
        id_vehiculo: self.idModelo
      })
      .then((response) => {
        if(response.IsValid) {
          self.listaImagenes = response.Data.map(item => {
            item.nombre = item.url_imagen.split('\\').pop();
            item.url_imagen = `${config.API_URL}/${item.url_imagen}`;

            return item;
          });
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-imagenes');
    },
    modalEliminarImagen(imagen) {
      this.imagenEliminar = { ...imagen };
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminarImagen() {
      let self = this;
      services.EliminarImagenVehiculo({
        id: self.imagenEliminar.id,
        id_vehiculo: self.imagenEliminar.id_vehiculo
      })
      .then((response) => {
        if (response.IsValid) {
          self.imagenEliminar = {};
          this.$bvModal.hide('bv-modal-advertencia');
          self.mostrarToast("success", response.Message);
          self.obtenerListaImagenes();
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    }
  },
};
</script>

<style scoped>
</style>
