<template>
  <div>
    <estructura-base>
      <b-container style="margin-bottom: 50px;">
        <b-card 
          class="text-left mt-5 mb-1"
          header-bg-variant="light"
        >
          <template #header>
            <b-row align-v="center">
              <b-col md="auto">
                <h4 class="mb-0">Contacto</h4>
              </b-col>
            </b-row>
          </template>
          <b-form>
            <b-form-group
              id="input-group"
              label="Liga de whatsapp para costos de mantenimiento:"
              label-for="input"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input"
                v-model="datosConfig.liga_whatsapp_mantenimiento"
                type="text"
                required
                placeholder="Whatsapp costos de mantenimiento"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Liga de whatsapp para financiamiento:"
              label-for="input-1"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-1"
                v-model="datosConfig.liga_whatsapp_financiamiento"
                type="text"
                required
                placeholder="Whatsapp financiamiento"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Liga de whatsapp para frenos gratis:"
              label-for="input-2"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-2"
                v-model="datosConfig.liga_whatsapp_frenos"
                type="text"
                required
                placeholder="Whatsapp frenos gratis"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label="Liga de whatsapp para soporte a operadores:"
              label-for="input-3"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-3"
                v-model="datosConfig.liga_whatsapp_soporte"
                type="text"
                required
                placeholder="Whatsapp soporte a operadores"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              label="Liga de whatsapp para taller móvil:"
              label-for="input-4"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-4"
                v-model="datosConfig.liga_whatsapp_taller"
                type="text"
                required
                placeholder="Whatsapp taller móvil"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              label="Liga de whatsapp para cotización:"
              label-for="input-5"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-5"
                v-model="datosConfig.liga_whatsapp_cotizacion"
                type="text"
                required
                placeholder="Whatsapp cotización"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              label="Liga de whatsapp para refacciones:"
              label-for="input-6"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-6"
                v-model="datosConfig.liga_whatsapp_refacciones"
                type="text"
                required
                placeholder="Whatsapp refacciones"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-7"
              label="Liga de whatsapp para cursos ecodrive:"
              label-for="input-7"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-7"
                v-model="datosConfig.liga_whatsapp_cursos"
                type="text"
                required
                placeholder="Whatsapp cursos ecodrive"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-8"
              label="Liga de whatsapp para embrague gratis:"
              label-for="input-8"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-8"
                v-model="datosConfig.liga_whatsapp_embrague"
                type="text"
                required
                placeholder="Whatsapp embrague gratis"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-9"
              label="Correo de contacto:"
              label-for="input-9"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input-9"
                v-model="datosConfig.correo_contacto"
                type="text"
                required
                placeholder="Correo contacto"
              ></b-form-input>
            </b-form-group>
          </b-form>
          <b-col md="12">
            <b-input-group class="mb-2 mt-5">
              <b-button
                size="sm"
                @click="guardar()"
                class="mr-1 mx-auto"
              >
                Guardar
              </b-button>
            </b-input-group>
          </b-col>
        </b-card>

        <b-toast id="toast-modelo" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{textToast}}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
export default {
  name: 'Configuracion',
  components: { EstructuraBase },
  data() {
    return {
      datosConfig: {
        liga_whatsapp_mantenimiento: ""
      },          
      variantToast: "",
      textToast: "",
    }
  },
  created(){
    this.ObtenerConfiguracionGeneral();
  },
  methods: {
    ObtenerConfiguracionGeneral() {
      let self = this;
      services.ObtenerConfiguracionGeneral({
      }).then(response => {
        if(response.IsValid) {
          self.datosConfig = response.Data;

        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    guardar() {
      let self = this;

      if(!self.datosConfig.correo_contacto) {
        self.mostrarToast("warning", "El correo de contacto es requerido.");
        return;
      }
      if(!self.validateEmail(self.datosConfig.correo_contacto)) {
        self.mostrarToast("warning", "El formato de correo electrónico no es valido.")
        return;
      }
      
      services.GuardarConfiguracionGeneral(self.datosConfig)
      .then(response => {
        if(response.IsValid) {
          self.mostrarToast("success", response.Message);
          self.ObtenerConfiguracionGeneral();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    validateEmail(email) {
      var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      return re.test(email.toLowerCase());
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-modelo');
    }
  }
}
</script>

<style>
  .container-form { width: 400px; margin-top: 30vh; }
  /* .labels-inputs { font-weight: bold; } */

  #bv-modal-advertencia-series .icon-warning,
  #bv-modal-advertencia-modelos .icon-warning { font-size: 50px;}
</style>
