<template>
  <div>
    <estructura-base>

      <b-container>
        <b-card 
          class="text-left mt-5 mb-1"
          header-bg-variant="light"
        >
          <template #header>        
            <b-row align-v="center">
              <b-col md="auto">
                <h4 class="mb-0">Banners</h4>
              </b-col>
              <b-col class="text-right">
                <b-button @click="agregar()" variant="secondary" class="btn-agregar pl-3 pr-3"><i class="fas fa-plus mr-2"></i> Agregar</b-button>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col md="auto" class="ml-auto">
              <b-input-group size="sm" class="mb-2">
                <b-input-group-prepend is-text>
                  <b-icon icon="search" @click="obtenerImagenes(nombreBusqueda)"></b-icon>
                </b-input-group-prepend>
                <b-form-input type="search" placeholder="Buscar" v-model="nombreBusqueda" v-on:keyup.enter="obtenerImagenes(nombreBusqueda)"></b-form-input>
              </b-input-group>          
            </b-col>
          </b-row>
          <b-table
            small
            stacked="md"
            :items="listaImagenes"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :head-variant="'dark'"
            id="tabla-banners"
          >
            <template #cell(fecha_creacion)="row">
              {{row.item.fecha_creacion | moment("ll")}}
            </template>
            <template #cell(accion)="row">
              <b-button id="btn-editar" size="sm" @click="editar(row.item, row.index, $event.target)" text-danger class="mr-1 btn-editar" variant="light">
                <i class="fas fa-pencil-alt"></i>
              </b-button>
              <!-- <b-tooltip target="btn-editar" triggers="hover">
                Editar
              </b-tooltip> -->
              <b-button id="btn-eliminar" size="sm" @click="modalEliminar(row.item, row.index, $event.target)" class="btn-eliminar" variant="light">
                <i class="fas fa-trash-alt"></i>
              </b-button>
              <!-- <b-tooltip target="btn-eliminar" triggers="hover">
                Eliminar
              </b-tooltip> -->
            </template>
          </b-table>
          <div v-if="!listaImagenes.length">No hay registros para mostrar</div>
          <b-col md="auto" v-if="listaImagenes.length">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="tabla-banners"
              align="right"
            ></b-pagination>
          </b-col>
        </b-card>

        <b-modal v-model="showModal" no-close-on-backdrop no-close-on-esc id="agregar" size="lg" :title="tituloModal">
          <b-form>
            <b-input-group>
              <b-form-input placeholder="Nombre" v-model="banner.nombre"></b-form-input>
            </b-input-group>
          </b-form>
          <b-form-file
            v-show="!editarBanner || (editarBanner && !banner.url_imagen)"
            class="mt-2"
            v-model="banner.imagen"
            placeholder="Seleccionar o arrastrar imagen"
            drop-placeholder="Suelta la imagen aquí..."
            ref="file-input"
            :change="getBase64()"
            accept=".jpeg, .jpg, .png"
          ></b-form-file>

          <div v-if="banner.url_imagen" class="mt-4 contenedor-imagen">
            <span @click="limpiarInput()"><i class="fas fa-trash-alt boton-eliminar-imagen"></i></span>
            <b-img center :src="banner.url_imagen" alt="Responsive image"></b-img>
          </div>
          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="limpiarFormulario()">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="guardar()">
              Guardar
            </b-button>
          </template>
        </b-modal>

        <b-modal id="bv-modal-advertencia" hide-header>
          <div class="d-block text-center">
            <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
            <h6>¿Esta seguro que desea eliminar el registro?</h6>
          </div>
          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="eliminar()">
              Aceptar
            </b-button>
          </template>
        </b-modal>

        <b-toast id="my-toast" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{textToast}}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
let config = require("@/config")
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
export default {
  components: { EstructuraBase },
  name: "Banner",
  props: {},
  data() {
    return {
      variantToast: "",
      textToast: "",
      tituloModal: "Agregar Imagen",
      showModal: false,
      banner: {
        url_imagen: null
      },
      nombreBusqueda: "",
      editarBanner: false,
      listaImagenes: [],
      fields: [
        {
          key: "nombre",
          label: "Nombre",
        },
        {
          key: "fecha_creacion",
          label: "Fecha de creación",
        },
        { key: "accion", label: "Acciones" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      imagenBase64: null
    };
  },
  computed: {
  },
  created() {
    this.obtenerImagenes();
  },
  mounted() {
    // this.totalRows = this.items.length;
  },
  methods: {
    getBase64() {
      let self = this;
      const tamaniomax = 2; //2mb
      const formato = ".jpeg, .jpg, .png";
      if(this.banner.imagen) {
        var file = self.banner.imagen;
        // SI VIENE ALGO
        var extension = file.type.split("/")[1];
        var tamanioArchivo = file.size;
        var tamanioMax = tamaniomax * 1000000;

        // VALIDAR NOMBRE ARCHIVO
        var patt = new RegExp(/^[A-Za-z0-9\s.,-_ ]+$/g);

        if(!patt.test(file.name)) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", "Revise que el nombre de la imagen no tenga caracteres especiales.");
          return
        }

        //SI SE PIDEN UN FORMATO ESPECIFICO
        var formatos = formato ? formato.split(',') : [];

        //SI SOLO SE ADMITE UN FORMATO Y NO ES CORRECTO
        if (formatos.length == 1 && `.${extension}` !== formato) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El formato permitido es ${formato.replace(/[.]/g,"")}`);
          return
        } else if (formatos.length > 1) {
          //SI SE ADMITEN VARIOS FORMATOS
          var formatoValido;
          formatos.filter(formato => {
            if (`.${extension}` === formato.trim()) {
              return formatoValido = true;
            }
          })

          //SI EL FORMATO DEL ARCHIVO NO COINSIDE CON LOS PERMITIDOS
          if (!formatoValido) {
            self.limpiarInput();
            this.$refs['file-input'].reset();
            self.mostrarToast("warning", `Los formatos permitidos son ${formato.replace(/[.]/g,"")}`);
            return
          }
        }

        //SI TAMAÑO MAYOR A TAMAÑO MAXIMO
        if (tamanioArchivo > tamanioMax) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El peso máximo es de ${tamaniomax}MB`);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(self.banner.imagen);
        reader.onload = (loadEvent) => {
          self.banner.url_imagen = loadEvent.target.result;
        };
      }
    },
    limpiarInput() {
      // this.$refs["file-input"].reset();
      this.banner.imagen ? this.banner.imagen = null : null ;
      this.banner.url_imagen = null;
    },
    obtenerImagenes(nombre) {
      let self = this;
      services.ObtenerListaImagenesBanner({
        nombre: nombre ? nombre :  ""
      }).then(response => {
        if(response.IsValid) {
          self.listaImagenes = response.Data.map(item => {
            item.url_imagen = item.url_imagen ? `${config.API_URL}/${item.url_imagen}` : "";
            return item;
          });

          self.totalRows = response.Data.length;
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    guardar() {
      let self = this;

      if(!self.banner.nombre) {
        self.mostrarToast("warning", "El nombre es requerido.");
        return;
      }
      if(!self.banner.url_imagen) {
        self.mostrarToast("warning", "La imagen es requerida.");
        return;
      }

      if(self.editarBanner) {
        const params = {
          ...self.banner,
          imagen_base64: self.banner.url_imagen.indexOf("data:image") > -1 ? self.banner.url_imagen : null
        }
        self.guardarActualizar("EditarImagenBanner", params);
      } else {
        const params = {
          nombre: self.banner.nombre,
          imagen_base64: self.banner.url_imagen
        }
        self.guardarActualizar("GuardarImagenBanner", params);
      }

      this.showModal = false;
      this.banner = {};

    },
    guardarActualizar(servicio, parametros) {
      let self = this;
      services[servicio](parametros).then(response => {
        if(response.IsValid) {
          self.mostrarToast("success", response.Message);
          self.obtenerImagenes();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    agregar() {
      this.editarBanner = false;
      this.tituloModal = "Agregar Imagen";
      this.$root.$emit("bv::show::modal", 'agregar');
      this.limpiarFormulario();
    },
    editar(imagen) {
      this.editarBanner = true;
      this.tituloModal = "Editar Imagen";
      this.$root.$emit("bv::show::modal", 'agregar');
      this.banner = {...imagen};
    },
    modalEliminar(imagen) {
      this.imagenEliminarId = imagen.id;
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminar() {
      let self = this;
      self.$bvModal.hide('bv-modal-advertencia');
      services.EliminarImagenBanner({
        id: self.imagenEliminarId
      }).then(response => {
        if(response.IsValid) {
          self.mostrarToast("success", response.Message);
          self.obtenerImagenes();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    limpiarFormulario() {
      this.showModal = false
      this.banner = {
        imagen: null,
        url_imagen: null
      };
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('my-toast');
    }
  },
};
</script>

<style>
  .btn-agregar {
    width: 150px;
    margin-left: auto;
  }

  .btn-editar { color: #007bff !important; }
  .btn-eliminar { color: #dc3545 !important; }

  .contenedor-imagen { position: relative; }
  .contenedor-imagen img { max-width: 450px; }
  .boton-eliminar-imagen {
    color: #dc3545;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  #bv-modal-advertencia .icon-warning { font-size: 50px;}
  
</style>
