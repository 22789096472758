<template>
  <div>
    <estructura-base>
      <b-container>
        <b-card no-body class="mt-5 mb-1">
          <b-tabs card :title-item-class="'tab-title-class'">
            <b-tab title="Euros" active>
              <euros></euros>
            </b-tab>
            <b-tab title="Series" >
              <series></series>
            </b-tab>
            <b-tab title="Modelos" >
              <cat-modelos></cat-modelos>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
import CatModelos from '../components/CatModelos.vue'
import EstructuraBase from '../components/EstructuraBase.vue'
import Euros from '../components/Euros.vue'
import Series from '../components/Series.vue'
export default {
  name: 'CatalogosModelos',
  components: { EstructuraBase, Euros, Series, CatModelos },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
  .container-form { width: 400px; margin-top: 30vh; }
  /* .labels-inputs { font-weight: bold; } */
</style>
