<template>
  <div>
    <estructura-base>
      <b-container>
        <b-card 
          class="text-left mt-5 mb-1"
          header-bg-variant="light"
        >
          <template #header>        
            <b-row align-v="center">
              <b-col md="auto">
                <h4 class="mb-0">Refacciones</h4>
              </b-col>
              <b-col class="text-right">
                <b-button @click="agregar()" variant="secondary" class="btn-agregar pl-3 pr-3"><i class="fas fa-plus mr-2"></i> Agregar</b-button>          
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col md="auto" class="ml-auto">
              <b-input-group size="sm" class="mb-2">
                <b-input-group-prepend is-text>
                  <b-icon icon="search" @click="obtenerRefacciones(textoBusqueda)"></b-icon>
                </b-input-group-prepend>
                <b-form-input type="search" placeholder="Buscar" v-model="textoBusqueda" v-on:keyup.enter="obtenerRefacciones(textoBusqueda)"></b-form-input>
              </b-input-group>          
            </b-col>
          </b-row>
          <b-table
            small
            stacked="md"
            :items="listaRefacciones"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :head-variant="'dark'"
          >
            <template #cell(fecha_creacion)="row">
              {{row.item.fecha_creacion | moment("ll")}}
            </template>
            <template #cell(accion)="row">
              <b-button id="btn-editar" size="sm" @click="editar(row.item, row.index, $event.target)" text-danger class="mr-1 btn-editar" variant="light">
                <i class="fas fa-pencil-alt"></i>
              </b-button>
              <b-button id="btn-eliminar" size="sm" @click="modalEliminar(row.item, row.index, $event.target)" class="btn-eliminar" variant="light">
                <i class="fas fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
          <div class="text-center" v-if="!listaRefacciones.length">No hay registros para mostrar</div>
          <b-col md="auto" v-if="listaRefacciones.length">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="tabla-refacciones"
              align="right"
            ></b-pagination>
          </b-col>
        </b-card>

        <b-modal v-model="showModal" id="agregar" size="lg" :title="tituloModal">
          <b-form-group
            id="input-group-2"
            label="Nombre:"
            label-for="input-2"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-2"
              v-model="refaccionSeleccionada.nombre"
              type="text"
              required
              placeholder="Nombre"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Dirección:"
            label-for="input-1"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-1"
              v-model="refaccionSeleccionada.direccion"
              type="text"
              required
              placeholder="Dirección"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Teléfono:"
            label-for="input-3"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-3"
              v-model="refaccionSeleccionada.telefono"
              type="text"
              required
              placeholder="Teléfono"
              maxlength="10"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            label="Horario:"
            label-for="input-4"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-4"
              v-model="refaccionSeleccionada.horario"
              type="text"
              required
              placeholder="Horario"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-5"
            label="Longitud:"
            label-for="input-5"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-5"
              v-model="refaccionSeleccionada.longitud"
              type="text"
              required
              placeholder="Longitud"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-6"
            label="Latitud:"
            label-for="input-6"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-6"
              v-model="refaccionSeleccionada.latitud"
              type="text"
              required
              placeholder="Latitud"
            ></b-form-input>
          </b-form-group>

          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="showModal = false">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="guardar()">
              Guardar
            </b-button>
          </template>
        </b-modal>

        <b-modal id="bv-modal-advertencia" hide-header>
          <div class="d-block text-center">
            <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
            <h6>¿Esta seguro que desea eliminar el registro?</h6>
          </div>
          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="eliminar()">
              Aceptar
            </b-button>
          </template>
        </b-modal>

        <b-toast id="toast-modelo" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{textToast}}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
export default {
  name: 'Refacciones',
  components: {EstructuraBase},
  data() {
    return {
      listaRefacciones: [],
      showModal: false,
      tituloModal: "Agregar punto de refacción",
      refaccionSeleccionada: {},
      editarRefaccion: false,
      variantToast: "",
      textToast: "",
      textoBusqueda: "",
      idRefaccion: 1,
      fields: [
        {
          key: "nombre",
          label: "Nombre",
        },
        {
          key: "telefono",
          label: "Teléfono",
        },
        {
          key: "fecha_creacion",
          label: "Fecha de creación",
        },
        { key: "accion", label: "Acciones" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    }
  },
  created() {
    this.obtenerRefacciones();
  },
  methods: {
    agregar() {
      this.tituloModal = "Agregar punto de refacción";
      this.showModal = true;
      this.refaccionSeleccionada = {};
    },
    guardar() {
      let self = this;
      if(!self.refaccionSeleccionada.nombre) {
        self.mostrarToast("warning", "El nombre es requerido.");
        return;
      }
      if(!self.refaccionSeleccionada.direccion) {
        self.mostrarToast("warning", "La dirección es requerida.");
        return;
      }      
      if(!self.refaccionSeleccionada.telefono) {
        self.mostrarToast("warning", "El télefono es requerido.");
        return;
      }
      if(self.refaccionSeleccionada.telefono.length < 10) {
        self.mostrarToast("warning", "El télefono debe de tener 10 dígitos.");
        return;
      }
      if(!self.refaccionSeleccionada.horario) {
        self.mostrarToast("warning", "El horario es requerido.");
        return;
      }
      if(!self.refaccionSeleccionada.longitud) {
        self.mostrarToast("warning", "La longitud es requerida.");
        return;
      }
      if(!self.refaccionSeleccionada.latitud) {
        self.mostrarToast("warning", "La latitud es requerida.");
        return;
      }

      if(self.refaccionSeleccionada.id) {
        self.editarRefaccion = false;
        self.guardarActualizar("EditarPuntoRefaccion");
      } else {
        self.guardarActualizar("GuardarPuntoRefaccion");
      }
    },
    guardarActualizar(servicio) {
      let self = this;
      services[servicio](self.refaccionSeleccionada)
      .then(response => {
        if(response.IsValid) {
          self.showModal = false;
          self.refaccionSeleccionada = {};
          self.mostrarToast("success", response.Message);
          self.obtenerRefacciones();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    editar(refaccion) {
      this.editarRefaccion = true;
      this.tituloModal = "Editar punto de refacción";
      this.showModal = true;
      this.refaccionSeleccionada = refaccion;
    },
    obtenerRefacciones(texto) {
      let self = this;
      services.ObtenerListaPuntosRefacciones({
        texto_busqueda: texto ? texto : ""
      }).then(response => {
        if(response.IsValid) {
          self.listaRefacciones = response.Data;

          self.totalRows = response.Data.length;
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    modalEliminar(refaccion) {
      this.refaccionEliminar = refaccion;
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminar() {
      let self = this;
      services.EliminarPuntoRefaccion({
        id: self.refaccionEliminar.id
      }).then(response => {
        if(response.IsValid) {          
          self.$bvModal.hide('bv-modal-advertencia');
          self.mostrarToast("success", response.Message);
          self.refaccionEliminar = {};
          self.obtenerRefacciones();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-modelo');
    }
  }
}
</script>

<style scoped>
  .btn-editar { color: #007bff; }
  .btn-eliminar { color: #dc3545; }
</style>
