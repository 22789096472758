<template>
  <div>
    <estructura-base>
      <b-container>
        <b-row>
          <b-col
            ><h3 class="text-left mt-3 mb-5">{{ titulo }}</h3></b-col
          >
        </b-row>

        <b-card no-body>
          <b-tabs card :title-item-class="'tab-title-class'">
            <b-tab title="Datos Generales" active>
              <b-card-body>
                <b-row>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-select
                        v-model="modelo.id_norma"
                        :options="listaNormas"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- seleccionar Euro --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-select
                        v-model="modelo.id_serie"
                        :options="listaSeries"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- seleccionar Serie --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-select
                        v-model="modelo.id_modelo"
                        :options="listaModelos"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- seleccionar Modelo --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-input
                        placeholder="Peso Bruto vehicular"
                        v-model="modelo.peso_bruto"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-input
                        placeholder="Motor"
                        v-model="modelo.motor"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-input
                        placeholder="Torque máximo"
                        v-model="modelo.torque_maximo"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="4" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-input
                        placeholder="Potencia máxima"
                        v-model="modelo.potencia_maxima"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="8" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-file
                        v-model="modelo.ficha"
                        placeholder="Seleccionar ficha técnica"
                        drop-placeholder="Suelta el archivo aquí..."
                        ref="file-input"
                        :change="getBase64()"
                        accept=".pdf"
                      ></b-form-file>
                      <a
                        v-if="modelo.ficha_tecnica"
                        class="enlace-pdf"
                        target="_blank"
                        v-bind:href="modelo.ficha_tecnica"
                        v-b-tooltip.hover
                        title="Ficha técnica actual"
                        ><i class="far fa-file-pdf icon-pdf"></i
                      ></a>
                    </b-input-group>
                  </b-col>
                  <b-col md="6" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-input
                        placeholder="Url vista 360 exterior"
                        v-model="modelo.url_vista360_exterior"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="6" sm="12">
                    <b-input-group class="mb-2">
                      <b-form-input
                        placeholder="Url vista 360 interior"
                        v-model="modelo.url_vista360_interior"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="12">
                    <b-input-group class="mb-2">
                      <tinymce
                        id="d1"
                        class="w-100"
                        :other_options="tinyOptions"
                        v-model="modelo.descripcion"
                      ></tinymce>
                    </b-input-group>
                  </b-col>
                  <b-col md="12">
                    <b-input-group class="mb-2 mt-5">
                      <b-button
                        size="sm"
                        @click="guardar()"
                        class="mr-1 mx-auto"
                      >
                        Guardar
                      </b-button>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab title="Versiones" :disabled="!idModelo">
              <versiones v-if="idModelo" :idModelo="idModelo"></versiones>
            </b-tab>
            <b-tab title="Imagenes" :disabled="!idModelo">
              <imagenesModelo
                v-if="idModelo"
                :idModelo="idModelo"
              ></imagenesModelo>
            </b-tab>
          </b-tabs>
        </b-card>

        <b-toast id="toast-modelo" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{ textToast }}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>


<script>
// import { VueEditor } from "vue2-editor";
let config = require("@/config");
import services from "@/services";
import tinymce from "vue-tinymce-editor";
import Versiones from "../components/Versiones.vue";
import ImagenesModelo from "../components/ImagenesModelo.vue";
import EstructuraBase from "../components/EstructuraBase.vue";
export default {
  name: "EditarModelos",
  props: {},
  components: {
    // VueEditor,
    tinymce,
    Versiones,
    ImagenesModelo,
    EstructuraBase,
  },
  data() {
    return {
      idModelo: null,
      titulo: "Agregar modelo",
      modelo: {
        id_serie: null,
        id_modelo: null,
        id_norma: null,
      },
      listaNormas: [],
      listaSeries: [],
      listaModelos: [],
      variantToast: "",
      textToast: "",
      tinyOptions: {
        height: "auto",
        menubar: false,
        statusbar: false,
        // language_url : 'https://dyonir.github.io/vue-tinymce-editor/static/langs/es_MX.js'
        language_url: "../../static/langs/es_MX.js",
      },
    };
  },
  created() {
    this.idModelo = this.$route.params.id;

    this.obtenerListaNormas();
    this.obtenerListaSeries();
    this.obtenerListaModelos();

    if (this.idModelo) {
      this.titulo = "Editar modelo";
      this.obtenerVehiculoPorId();
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getBase64() {
      let self = this;
      const tamaniomax = 2; //2mb
      const formato = ".pdf";
      if (self.modelo.ficha) {
        var file = self.modelo.ficha;
        // SI VIENE ALGO
        var extension = file.type.split("/")[1];
        var tamanioArchivo = file.size;
        var tamanioMax = tamaniomax * 1000000;

        // VALIDAR NOMBRE ARCHIVO
        var patt = new RegExp(/^[A-Za-z0-9\s.,-_ ]+$/g);

        if(!patt.test(file.name)) {
          self.modelo.ficha = null;
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", "Revise que el nombre del archivo no tenga caracteres especiales.");
          return
        }

        //SI SOLO SE ADMITE UN FORMATO Y NO ES CORRECTO
        if (`.${extension}` !== formato) {
          self.modelo.ficha = null;
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El formato permitido es ${formato.replace(/[.]/g,"")}`);
          return;
        }

        //SI TAMAÑO MAYOR A TAMAÑO MAXIMO
        if (tamanioArchivo > tamanioMax) {
          self.modelo.ficha = null;
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El peso máximo es de ${tamaniomax}MB`);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(self.modelo.ficha);
        reader.onload = (loadEvent) => {
          self.modelo.ficha_tecnica_base64 = loadEvent.target.result;
        };
      } else {
        self.modelo.ficha_tecnica_base64 = null;
      }
    },
    guardar() {
      let self = this;

      if (self.idModelo) {
        self.modelo.id = self.idModelo;
        self.guardarActualizar("EditarVehiculo", true);
      } else {
        self.guardarActualizar("GuardarVehiculo");
      }
    },
    guardarActualizar(servicio) {
      let self = this;
      if (!self.modelo.id_norma) {
        self.mostrarToast("warning", "La euro es requerida.");
        return;
      }
      if (!self.modelo.id_serie) {
        self.mostrarToast("warning", "La serie es requerida.");
        return;
      }
      if (!self.modelo.id_modelo) {
        self.mostrarToast("warning", "El modelo es requerido.");
        return;
      }
      if (!self.modelo.peso_bruto) {
        self.mostrarToast("warning", "El peso bruto vehicular es requerido.");
        return;
      }
      if (!self.modelo.motor) {
        self.mostrarToast("warning", "El motor es requerido.");
        return;
      }
      if (!self.modelo.torque_maximo) {
        self.mostrarToast("warning", "El torque máximo es requerido.");
        return;
      }
      if (!self.modelo.potencia_maxima) {
        self.mostrarToast("warning", "La potencia máxima es requerida.");
        return;
      }

      services[servicio](self.modelo)
        .then((response) => {
          if (response.IsValid) {
            self.idModelo = response.Data;
            self.mostrarToast("success", response.Message);

            if (!this.$route.params.id) {
              this.$router.push({
                name: "editarModelos",
                params: { id: response.Data },
              });
            }
            self.obtenerVehiculoPorId();
          }
        })
        .catch((e) => {
          console.log(e);
          self.mostrarToast(
            "warning",
            "Ocurrio un error, inténtelo de nuevo más tarde."
          );
        });
    },
    obtenerListaNormas() {
      let self = this;
      services
        .ObtenerListaNormas({})
        .then((response) => {
          if (response.IsValid) {
            self.listaNormas = response.Data.map((item) => {
              (item.value = item.id), (item.text = item.descripcion);
              return item;
            });
          } else {
            self.mostrarToast("warning", response.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          self.mostrarToast(
            "warning",
            "Ocurrio un error, inténtelo de nuevo más tarde."
          );
        });
    },
    obtenerListaSeries() {
      let self = this;
      services
        .ObtenerListaSeries({})
        .then((response) => {
          if (response.IsValid) {
            self.listaSeries = response.Data.map((item) => {
              (item.value = item.id), (item.text = item.descripcion);
              return item;
            });
          } else {
            self.mostrarToast("warning", response.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          self.mostrarToast(
            "warning",
            "Ocurrio un error, inténtelo de nuevo más tarde."
          );
        });
    },
    obtenerListaModelos() {
      let self = this;
      services
        .ObtenerListaModelos({})
        .then((response) => {
          if (response.IsValid) {
            self.listaModelos = response.Data.map((item) => {
              (item.value = item.id), (item.text = item.descripcion);
              return item;
            });
          } else {
            self.mostrarToast("warning", response.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          self.mostrarToast(
            "warning",
            "Ocurrio un error, inténtelo de nuevo más tarde."
          );
        });
    },
    obtenerVehiculoPorId() {
      let self = this;
      services
        .ObtenerVehiculoPorId({
          id_vehiculo: self.idModelo,
        })
        .then((response) => {
          if (response.IsValid) {
            self.modelo = response.Data;
            self.modelo.descripcion = self.modelo.descripcion
              ? self.modelo.descripcion
              : "";
            self.modelo.ficha_tecnica = self.modelo.ficha_tecnica
              ? `${config.API_URL}/${self.modelo.ficha_tecnica}`
              : "";
          } else {
            self.mostrarToast("warning", response.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          self.mostrarToast(
            "warning",
            "Ocurrio un error, inténtelo de nuevo más tarde."
          );
        });
    },
    mostrarToast(variante, mensaje) {
      this.variantToast = variante;
      this.textToast = mensaje;
      this.$bvToast.show("toast-modelo");
    },
  },
};
</script>

<style>
.color {
  color: #263248;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar" !important;
}
/* .tabs .card-header {
    background-color: #343a40 !important; 
  } */
.enlace-pdf {
  padding-top: 8px;
  margin-left: 10px;
}
.enlace-pdf .icon-pdf {
  font-size: 20px;
}
</style>
