<template>
  <div>
    <estructura-base>
      <b-container>
        <b-card 
          class="text-left mt-5 mb-1"
          header-bg-variant="light"
        >
          <template #header>
            <b-row align-v="center">
              <b-col md="auto">
                <h4 class="mb-0">Cambiar contraseña</h4>
              </b-col>
            </b-row>
          </template>
          <b-form>
            <b-form-group
              id="input-group"
              label="Contraseña actual"
              label-for="input"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input"
                v-model="datos.password_actual"
                type="password"
                required
                placeholder="Contraseña actual"
              ></b-form-input>
            </b-form-group>
          </b-form>
          <b-form>
            <b-form-group
              id="input-group"
              label="Contraseña"
              label-for="input1"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input1"
                v-model="datos.password_nuevo"
                type="password"
                required
                placeholder="Contraseña"
              ></b-form-input>
            </b-form-group>
          </b-form>
          <b-form>
            <b-form-group
              id="input-group"
              label="Confirmar contraseña"
              label-for="input2"
              class="text-left labels-inputs"
            >
              <b-form-input
                id="input2"
                v-model="datos.password_confirmacion"
                type="password"
                required
                placeholder="Confirmar contraseña"
              ></b-form-input>
            </b-form-group>
          </b-form>

          <b-col md="12">
            <b-input-group class="mb-2 mt-5">
              <b-button
                size="sm"
                @click="guardar()"
                class="mr-1 mx-auto"
              >
                Guardar
              </b-button>
            </b-input-group>
          </b-col>
        </b-card>

        <b-toast id="toast-modelo" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{textToast}}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
import { mapState } from 'vuex';
export default {
  name: 'Configuracion',
  components: { EstructuraBase },
  data() {
    return {
      datos: {
        password_actual: "",
        password_nuevo: "",
        password_confirmacion: ""
      },          
      variantToast: "",
      textToast: "",
    }
  },
  computed: {
    ...mapState(['usuario'])
  },
  methods: {
    guardar() {
      let self = this;
      console.log(self.datos)
      if(!self.datos.password_actual) {
        self.mostrarToast("warning", "La contraseña actual es requerida.");
        return;
      }
      if(!self.datos.password_nuevo) {
        self.mostrarToast("warning", "La contraseña es requerida.");
        return;
      }
      if(self.datos.password_nuevo != self.datos.password_confirmacion) {
        self.mostrarToast("warning", "Las contraseñas no coinciden.");
        return;
      }
      
      services.cambiarContrasena({
        id_usuario: self.usuario.id,
        password_actual: self.datos.password_actual,
        password_nuevo: self.datos.password_nuevo
      })
      .then(response => {
        self.datos = {};
        if(response.IsValid) {
          self.mostrarToast("success", response.Message);
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-modelo');
    }
  }
}
</script>

<style>
  .container-form { width: 400px; margin-top: 30vh; }
  /* .labels-inputs { font-weight: bold; } */

  #bv-modal-advertencia-series .icon-warning,
  #bv-modal-advertencia-modelos .icon-warning { font-size: 50px;}
</style>
