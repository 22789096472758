<template>
  <div>
    <b-card-body>
      <!-- agregar nueva version -->
      <b-row>
        <b-col>
          <h5 class="text-left">{{titulo}}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Versión" v-model="versionSeleccionada.version"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Capacidad máxima de carga sobre chasis (kg)" v-model="versionSeleccionada.capacidad_maxima"></b-form-input>
          </b-input-group>
        </b-col>

        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Transmisión" v-model="versionSeleccionada.transmision"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Espacio disponible para aplicación" v-model="versionSeleccionada.espacio_disponible"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Distancia entre ejes" v-model="versionSeleccionada.distancia_ejes"></b-form-input>
          </b-input-group>
        </b-col>

        <b-col md="12">
          <b-input-group class="mb-5 mt-3" style="justify-content: flex-end;">
            <b-button size="sm" variant="outline-danger" @click="limpiar()" class="mr-1">
              Limpiar
            </b-button>
            <b-button size="sm" @click="guardar()" class="mr-1">
              Guardar
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Tabla con lista de versiones -->
      <b-table
        small
        stacked="md"
        :items="listaVersiones"
        :fields="fields"
        :head-variant="'dark'"
        class="tabla-versiones small"
      >
        <template #cell(fecha)="row">
          {{ row.item.fecha | moment("ll") }}
        </template>
        <template #cell(accion)="row">
          <b-button
              size="sm"
              @click="editar(row.item, row.index, $event.target)"
              class="mr-1 btn-editar"
              variant="light"
            >
            <i class="fas fa-pencil-alt"></i>
          </b-button>
          <b-button
            size="sm"
            @click="modalEliminar(row.item, row.index, $event.target)"
            variant="light"
            class="btn-eliminar"
          >
            <i class="fas fa-trash-alt"></i>
          </b-button>
        </template>
      </b-table>
      <div v-if="!listaVersiones.length">No hay registros para mostrar</div>
    </b-card-body>
    <b-modal id="bv-modal-advertencia-modelos" hide-header>
      <div class="d-block text-center">
        <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
        <h6>¿Esta seguro que desea eliminar el registro?</h6>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia-modelos')">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="eliminarVersion()">
          Aceptar
        </b-button>
      </template>
    </b-modal>

    <b-toast id="my-toast" :variant="variantToast" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong>{{textToast}}</strong>
        </div>
      </template>
    </b-toast>
  </div>
</template>

<script>
import services from "@/services";
export default {
  name: "versiones",
  props: ['idModelo'],
  data() {
    return {
      titulo: "Agregar nueva versión",
      listaVersiones: [],
      versionSeleccionada: {},
      variantToast: "",
      textToast: "",
      fields: [
        {
          key: "version",
          label: "Versión",
        },
        {
          key: "capacidad_maxima",
          label: "Capacidad máxima",
        },
        {
          key: "espacio_disponible",
          label: "Espacio disponible para aplicación",
        },
        {
          key: "distancia_ejes",
          label: "Distancia entre ejes",
        },
        {
          key: "transmision",
          label: "Transmisión",
        },
        { key: "accion", label: "Acciones" },
      ]
    };
  },
  created() {
    this.obtenerListaVersiones();
  },
  computed: {},
  mounted() {},
  methods: {
    guardar() {
      if(!this.versionSeleccionada.version){
        this.mostrarToast("warning", "La versión es requerida.");
        return;
      }
      if(!this.versionSeleccionada.capacidad_maxima){
        this.mostrarToast("warning", "La capacidad máxima de carga sobre chasis es requerida.");
        return;
      }
      if(!this.versionSeleccionada.transmision){
        this.mostrarToast("warning", "La transmisión es requerida.");
        return;
      }
      // if(!this.versionSeleccionada.espacio_disponible){
      //   this.mostrarToast("warning", "El espacio disponible para aplicación es requerido.");
      //   return;
      // }
      if(!this.versionSeleccionada.distancia_ejes){
        this.mostrarToast("warning", "La distancia entre ejes es requerida.");
        return;
      }

      if(this.versionSeleccionada.id) {
        this.guardarActualizar("EditarVersionVehiculo");
      } else {
        this.guardarActualizar("GuardarVersionVehiculo");
      }
      
    },
    guardarActualizar(servicio) {
      let self = this;
      services[servicio]({
        id_vehiculo: self.idModelo,
        ...self.versionSeleccionada
      })
      .then((response) => {
        if (response.IsValid) {
          self.obtenerListaVersiones();
          self.versionSeleccionada = {};
          self.titulo = "Agregar nueva versión";
          self.mostrarToast("success", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    editar(version) {
      this.titulo = "Editar versión";
      this.versionSeleccionada = {...version};
    },
    obtenerListaVersiones() {
      let self = this;
      services.ObtenerListaVersionesVehiculo({
        id_vehiculo: self.idModelo
      })
      .then((response) => {
        if (response.IsValid) {
          self.listaVersiones = response.Data;
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    modalEliminar(version) {
      this.versionEliminar = {...version};
      this.$bvModal.show('bv-modal-advertencia-modelos');
    },
    eliminarVersion() {
      let self = this;
      services.EliminarVersionVehiculo({
        id: self.versionEliminar.id
      })
      .then((response) => {
        if (response.IsValid) {
          self.versionEliminar = {};
          this.$bvModal.hide('bv-modal-advertencia-modelos');
          self.mostrarToast("success", response.Message);
          self.obtenerListaVersiones();
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    limpiar() {
      this.titulo = "Agregar nueva versión"
      this.versionSeleccionada = {};
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('my-toast');
    }
  },
};
</script>

<style scoped>
  .btn-editar { color: #007bff; }
  .btn-eliminar { color: #dc3545; }

  .tabla-versiones th, .tabla-versiones td{ font-size: 14px !important; }
</style>
