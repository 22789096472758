import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// componentes
import Login from './views/Login.vue'
import Banner from './views/Banner.vue';
import Modelos from './views/Modelos.vue';
import EditarModelos from './views/EditarModelos.vue';
import CostosMantenimiento from './views/CostosMantenimiento.vue';
import Refacciones from './views/Refacciones.vue';
import SuperTecnicos from './views/SuperTecnico.vue';
import cursosEcodrive from './views/CursosEcodrive.vue';
import ConfiguracionGeneral from './views/ConfiguracionGeneral.vue';
import CambiarContrasena from './views/CambiarContrasena.vue';
import Catalogos from './views/CatalogosModelos.vue';

export default new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name:'login', component: Login },
    { path: '/login', name:'login', component: Login },
    { path: '/banner', name:'banner', meta: {requiresAuth: true}, component: Banner },
    { path: '/modelos', name:'modelos', meta: {requiresAuth: true}, component: Modelos },
    { path: '/catalogos', name:'catalogos', meta: {requiresAuth: true}, component: Catalogos },
    { path: '/detalle-modelo/:id?', name:'editarModelos', meta: {requiresAuth: true}, component: EditarModelos },
    // { path: '/soporte-total', name:'soporteTotal', component: SoporteTotal },
    { path: '/costos-mantenimiento', name:'costosMantenimiento', meta: {requiresAuth: true}, component: CostosMantenimiento },
    { path: '/refacciones', name:'refacciones', meta: {requiresAuth: true}, component: Refacciones },
    { path: '/super-tecnico', name:'SuperTecnicos', meta: {requiresAuth: true}, component: SuperTecnicos },
    { path: '/cursos-ecodrive', name:'cursosEcodrive', meta: {requiresAuth: true}, component: cursosEcodrive },
    { path: '/configuracion-general', name:'configGeneral', meta: {requiresAuth: true}, component: ConfiguracionGeneral },
    { path: '/cambio-contrasena', name:'cambioContrasena', meta: {requiresAuth: true}, component: CambiarContrasena },
  ]
})