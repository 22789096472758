<template>
  <div v-if="loading" class="loading">
    <b-spinner variant="danger" label="Spinning" class="spinner-loading"></b-spinner>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "Loading",

  computed: {
    ...mapState(['loading'])
  }
};
</script>

<style scoped>
  .loading {
    background: #000000bf;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1111;
  }
  .spinner-loading { margin-top: calc(50vh - 45px); width: 3rem; height: 3rem; }
</style>
