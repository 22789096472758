import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
import 'bootstrap';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookie from 'vue-cookie';

const moment = require('moment')
require('moment/locale/es')



moment.locale('es');

import interceptorsSetup from '@/helpers/interceptors'
interceptorsSetup()

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import datePicker from 'vue-bootstrap-datetimepicker';
import datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

jquery.extend(true, jquery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

Vue.use(VueCookie);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin) 
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VueSidebarMenu)
Vue.use(datePicker);
Vue.use(datetime)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
