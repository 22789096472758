import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie';

Vue.use(Vuex);
Vue.use(VueCookie);

export default new Vuex.Store({
  state: {
    loading: false,
    correousuario: Vue.cookie.get('correo'),
    auth: Vue.cookie.get('auth'),
    usuario: null
  },
  mutations: {
    actualizarLoading(state, param) {
      state.loading = param;
    },
    iniciarSesion(state, datos) {
      state.auth = true;
      Vue.cookie.set('auth', true, { expires: '1D' });
      state.correousuario = datos.email;
      Vue.cookie.set('correo', datos.email, { expires: '1D' });
      state.usuario = datos;
    },
    cerrarSesion(state) {
      state.auth = false;
      Vue.cookie.delete('auth');
      state.correousuario = null;
      Vue.cookie.delete('correo');
    }
  },
  actions: {
    iniciarSesion({ commit }, data) {
      commit("iniciarSesion", data);
    },
    cerrarSesion({ commit }) {
      commit("cerrarSesion");
    }
  }
})