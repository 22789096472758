<template>
  <div>
    <estructura-base>
      <b-container>
        <b-card 
          class="text-left mt-5 mb-1"
          header-bg-variant="light"
        >
          <template #header>        
            <b-row align-v="center">
              <b-col md="auto">
                <h4 class="mb-0">Super Técnico</h4>
              </b-col>
              <b-col class="text-right">
                <b-button @click="agregar()" variant="secondary" class="btn-agregar pl-3 pr-3"><i class="fas fa-plus mr-2"></i> Agregar</b-button>          
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col md="auto" class="ml-auto">
              <b-input-group size="sm" class="mb-2">
                <b-input-group-prepend is-text>
                  <b-icon icon="search" @click="obtenerListaTecnicos(textoBusqueda)"></b-icon>
                </b-input-group-prepend>
                <b-form-input type="search" placeholder="Buscar" v-model="textoBusqueda" v-on:keyup.enter="obtenerListaTecnicos(textoBusqueda)"></b-form-input>
              </b-input-group>          
            </b-col>
          </b-row>
          <b-table
            small
            stacked="md"
            :items="listaTecnicos"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :head-variant="'dark'"
          >
            <template #cell(fecha_creacion)="row">
              {{row.item.fecha_creacion | moment("ll")}}
            </template>
            <template #cell(accion)="row">
              <b-button id="btn-editar" size="sm" @click="editar(row.item, row.index, $event.target)" text-danger class="mr-1 btn-editar" variant="light">
                <i class="fas fa-pencil-alt"></i>
              </b-button>
              <b-button id="btn-eliminar" size="sm" @click="modalEliminar(row.item, row.index, $event.target)" class="btn-eliminar" variant="light">
                <i class="fas fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
          <div class="text-center" v-if="!listaTecnicos.length">No hay registros para mostrar</div>
          <b-col md="auto" v-if="listaTecnicos.length">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="tabla-tecnicos"
              align="right"
            ></b-pagination>
          </b-col>
        </b-card>

        <b-modal v-model="showModal" no-close-on-backdrop no-close-on-esc id="agregar" size="lg" :title="tituloModal">
          <b-form-group
            id="input-group-1"
            label="Título:"
            label-for="input-1"
            class="text-left labels-inputs"
          >
            <b-form-input
              id="input-1"
              v-model="tecnicoSeleccionado.titulo"
              type="text"
              required
              placeholder="Título"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Descripción:"
            label-for="input-2"
            class="text-left labels-inputs"
          >
            <b-form-textarea
              id="textarea"
              v-model="tecnicoSeleccionado.descripcion"
              placeholder="Descripción"
            ></b-form-textarea>
          </b-form-group>

          <b-form-file
            class="mt-2"
            v-show="!editarTecnico || (editarTecnico && !tecnicoSeleccionado.url_imagen)"
            v-model="tecnicoSeleccionado.imagen"
            placeholder="Seleccionar o arrastrar imagen"
            drop-placeholder="Suelta la imagen aquí..."
            ref="file-input" 
            :change="getBase64()"
            accept=".jpeg, .jpg, .png"
          ></b-form-file>

          <div v-if="tecnicoSeleccionado.url_imagen" class="mt-4 contenedor-imagen">
            <span @click="limpiarInput()"><i class="fas fa-trash-alt boton-eliminar-imagen"></i></span>
            <b-img center :src="tecnicoSeleccionado.url_imagen" alt="Responsive image"></b-img>
          </div>
          
          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="limpiarFormulario()">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="guardar()">
              Guardar
            </b-button>
          </template>
        </b-modal>

        <b-modal id="bv-modal-advertencia" hide-header>
          <div class="d-block text-center">
            <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
            <h6>¿Esta seguro que desea eliminar el registro?</h6>
          </div>
          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="eliminar()">
              Aceptar
            </b-button>
          </template>
        </b-modal>

        <b-toast id="toast-modelo" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{textToast}}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
let config = require("@/config")
export default {
  name: 'SuperTecnico',
  components: {EstructuraBase},
  data() {
    return {
      listaTecnicos: [],
      showModal: false,
      tituloModal: "Agregar técnico",
      textoBusqueda: "",
      tecnicoSeleccionado: {
        url_imagen: null
      },
      editarTecnico: false,
      variantToast: "",
      textToast: "",
      fields: [
        {
          key: "titulo",
          label: "Título",
        },
        {
          key: "fecha_creacion",
          label: "Fecha de creación",
        },
        { key: "accion", label: "Acciones" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    }
  },
  created() {
    this.obtenerListaTecnicos();
  },
  methods: {
    obtenerListaTecnicos(texto) {
      let self = this;
      services.ObtenerListaTecnicos({
        texto_busqueda: texto ? texto : ""
      }).then(response => {
        if(response.IsValid) {
          self.listaTecnicos = response.Data.map(item => {
            item.url_imagen = item.url_imagen ? `${config.API_URL}${item.url_imagen}` : "";
            return item;
          });

          self.totalRows = response.Data.length;
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    agregar() {
      this.tituloModal = "Agregar técnico";
      this.$bvModal.show('agregar');
      this.tecnicoSeleccionado = {
        url_imagen: null
      };
      this.editarTecnico = false;
    },
    guardar() {
      if(!this.tecnicoSeleccionado.titulo) {
        this.mostrarToast("warning", "El titulo es requerido.");
        return;
      }
      if(!this.tecnicoSeleccionado.descripcion) {
        this.mostrarToast("warning", "La descripción es requerida.");
        return;
      }
      if(!this.tecnicoSeleccionado.url_imagen) {
        this.mostrarToast("warning", "La imagen es requerida.");
        return;
      }

      const params = {
        ...this.tecnicoSeleccionado,
        imagen_base64: this.tecnicoSeleccionado.url_imagen.indexOf("data:image") > -1 ? this.tecnicoSeleccionado.url_imagen : null
      }
      if(this.tecnicoSeleccionado.id) {
        this.editarTecnico = false;        
        this.guardarActualizar("EditarTecnico", params);
      } else {
        this.guardarActualizar("GuardarTecnico", params);
      }
    },
    guardarActualizar(servicio, parametros){
      let self = this;
      services[servicio](parametros)
      .then(response => {
        if(response.IsValid) {
          this.$bvModal.hide('agregar');
          self.tecnicoSeleccionado = {};
          self.mostrarToast("success", response.Message);
          self.obtenerListaTecnicos();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      }); 
    },
    editar(tecnico) {
      this.editarTecnico = true;
      this.tituloModal = "Editar técnico";
      this.$bvModal.show('agregar');
      this.tecnicoSeleccionado = {...tecnico};
    },
    modalEliminar(tecnico) {
      this.tecnicoEliminar = tecnico;
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminar() {
      let self = this;
      services.EliminarTecnico({
        id: self.tecnicoEliminar.id
      }).then(response => {
        if(response.IsValid) {          
          self.$bvModal.hide('bv-modal-advertencia');
          self.mostrarToast("success", response.Message);
          self.tecnicoEliminar = {};
          self.obtenerListaTecnicos();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    getBase64() {
      let self = this;
      const tamaniomax = 2; //2mb
      const formato = ".jpeg, .jpg, .png";
      if(self.tecnicoSeleccionado.imagen) {
        var file = self.tecnicoSeleccionado.imagen;
        // SI VIENE ALGO
        var extension = file.type.split("/")[1];
        var tamanioArchivo = file.size;
        var tamanioMax = tamaniomax * 1000000;

        // VALIDAR NOMBRE ARCHIVO
        var patt = new RegExp(/^[A-Za-z0-9\s.,-_ ]+$/g);

        if(!patt.test(file.name)) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", "Revise que el nombre de la imagen no tenga caracteres especiales.");
          return
        }

        //SI SE PIDEN UN FORMATO ESPECIFICO
        var formatos = formato ? formato.split(',') : [];

        //SI SOLO SE ADMITE UN FORMATO Y NO ES CORRECTO
        if (formatos.length == 1 && `.${extension}` !== formato) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El formato permitido es ${formato.replace(/[.]/g,"")}`);
          return
        } else if (formatos.length > 1) {
          //SI SE ADMITEN VARIOS FORMATOS
          var formatoValido;
          formatos.filter(formato => {
            if (`.${extension}` === formato.trim()) {
              return formatoValido = true;
            }
          })

          //SI EL FORMATO DEL ARCHIVO NO COINSIDE CON LOS PERMITIDOS
          if (!formatoValido) {
            self.limpiarInput();
            this.$refs['file-input'].reset();
            self.mostrarToast("warning", `Los formatos permitidos son ${formato.replace(/[.]/g,"")}`);
            return
          }
        }

        //SI TAMAÑO MAYOR A TAMAÑO MAXIMO
        if (tamanioArchivo > tamanioMax) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El peso máximo es de ${tamaniomax}MB`);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(self.tecnicoSeleccionado.imagen);
        reader.onload = (loadEvent) => {
          self.tecnicoSeleccionado.url_imagen = loadEvent.target.result;
        };
      }      
    },
    limpiarInput() {
      this.tecnicoSeleccionado.imagen ? this.tecnicoSeleccionado.imagen = null : null ;
      this.tecnicoSeleccionado.url_imagen = null;
    },
    limpiarFormulario() {
      this.showModal = false
      this.tecnicoSeleccionado = {
        imagen: null,
        url_imagen: null
      }
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-modelo');
    }
  }
}
</script>

<style scoped>
  .btn-editar { color: #007bff; }
  .btn-eliminar { color: #dc3545; }

  .contenedor-imagen { position: relative; }
  .contenedor-imagen img { max-width: 450px; }
  .boton-eliminar-imagen {
    color: #dc3545;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
</style>
