<template>
  <div>
    <estructura-base>
      <b-container>
        <b-card
          class="text-left mt-5 mb-1"
          header-bg-variant="light"
        >
          <template #header>        
            <b-row align-v="center">
              <b-col md="auto">
                <h4 class="mb-0">Modelos</h4>
              </b-col>
              <b-col class="text-right">
                <router-link :to="{name: 'editarModelos'}">
                  <b-button variant="secondary" class="btn-agregar pl-3 pr-3"><i class="fas fa-plus mr-2"></i> Agregar</b-button>          
                </router-link>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col md="auto" class="ml-auto">
              <b-input-group size="sm" class="mb-2">
                <b-input-group-prepend is-text>
                  <b-icon icon="search" @click="obtenerVehiculos(textoBusqueda)"></b-icon>
                </b-input-group-prepend>
                <b-form-input type="search" placeholder="Buscar" v-model="textoBusqueda" v-on:keyup.enter="obtenerVehiculos(textoBusqueda)"></b-form-input>
              </b-input-group>          
            </b-col>
          </b-row>
          <b-table
            small
            stacked="md"
            :items="listaModelos"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :head-variant="'dark'"
          >
            <template #cell(fecha_creacion)="row">
              {{row.item.fecha_creacion | moment("ll")}}
            </template>
            <template #cell(accion)="row">
              <router-link :to="{name: 'editarModelos', params:{id: row.item.id}}">
                <b-button
                  size="sm"
                  class="mr-1 btn-editar"
                  variant="light"
                >
                  <i class="fas fa-pencil-alt"></i>
                </b-button>
              </router-link>
              <b-button size="sm" @click="modalEliminar(row.item, row.index, $event.target)" variant="light" class="btn-eliminar">
                <i class="fas fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
          <div class="text-center" v-if="!listaModelos.length">No hay registros para mostrar</div>
          <b-col md="auto" v-if="listaModelos.length">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="tabla-banners"
              align="right"
            ></b-pagination>
          </b-col>
        </b-card>
        <b-modal id="bv-modal-advertencia" hide-header>
          <div class="d-block text-center">
            <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
            <h6>¿Esta seguro que desea eliminar el registro?</h6>
          </div>
          <template #modal-footer>
            <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
              Cancelar
            </b-button>
            <b-button size="sm" variant="primary" @click="eliminar()">
              Aceptar
            </b-button>
          </template>
        </b-modal>
        <b-toast id="toast-modelo" :variant="variantToast" solid>
          <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
              <strong>{{textToast}}</strong>
            </div>
          </template>
        </b-toast>
      </b-container>
    </estructura-base>
  </div>
</template>

<script>
let config = require("@/config")
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
export default {
  components: { EstructuraBase },
  name: "Modelos",
  data() {
    return {
      showModal: false,
      modelo: {},
      editarModelo: false,
      variantToast: "",
      textToast: "",
      textoBusqueda: "",
      listaModelos: [
      ],
      fields: [
        {
          key: "norma",
          label: "Euro",
        },
        {
          key: "serie",
          label: "Serie",
        },
        {
          key: "modelo",
          label: "Modelo",
        },
        {
          key: "fecha_creacion",
          label: "Fecha de creación",
        },
        { key: "accion", label: "Acciones" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    };
  },
  computed: {
  },
  created() {
    this.obtenerVehiculos();
  },
  mounted() {    
  },
  methods: {
    obtenerVehiculos(busqueda) {
      let self = this;
      services.ObtenerListaVehiculos({
        texto_busqueda: busqueda ? busqueda : ""
      }).then(response => {
        if(response.IsValid) {
          self.listaModelos = response.Data.map(item => {
            item.ficha_tecnica = item.ficha_tecnica ? `${config.API_URL}/${item.ficha_tecnica}` : "";
            return item;
          });

          self.totalRows = response.Data.length;
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    modalEliminar(modelo) {
      this.modeloEliminar = {...modelo};
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminar() {
      let self = this;
      console.log(self.modeloEliminar);
      services.EliminarVehiculo({
        id: self.modeloEliminar.id
      }).then(response => {
        if(response.IsValid) {
          self.modeloEliminar = {};
          this.$bvModal.hide('bv-modal-advertencia');
          self.mostrarToast("success", response.Message);
          self.obtenerVehiculos();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      }).catch(e => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-modelo');
    }
  },
};
</script>

<style scoped>
  .btn-agregar {
    width: 150px;
    margin-left: auto;
  }

  .btn-editar { color: #007bff; }
  .btn-eliminar { color: #dc3545; }
</style>
