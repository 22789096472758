<template>
  <estructura-base>
    <b-container>
      <b-card 
        class="text-left mt-5 mb-1"
        header-bg-variant="light"
      >
        <template #header>
          <h4 class="mb-0">Costos de mantenimiento</h4>
        </template>
        <b-form-file
          class="mt-2"
          v-model="costos.imagen"
          placeholder="Seleccionar o arrastrar imagen"
          drop-placeholder="Suelta la imagen aquí..."
          ref="file-input" 
          :change="getBase64()"
          accept=".jpeg, .jpg, .png"
        ></b-form-file>

        <div v-if="costos.url_imagen" class="mt-4 contenedor-imagen">
          <b-button
            id="eliminarImagen"
              size="sm"
              variant="light"
              class="btn-eliminar boton-eliminar-imagen"
              @click="modalEliminar()"
            >
            <i class="fas fa-trash-alt"></i>
          </b-button>
          <!-- <span id="eliminarImagen" @click="limpiarInput()"><i class="fas fa-trash-alt boton-eliminar-imagen"></i></span> -->
          <b-tooltip target="eliminarImagen">Eliminar Imagen</b-tooltip>
          <b-img center :src="costos.url_imagen" class="imagen-seleccionada" alt="Responsive image"></b-img>
        </div>
      </b-card>

      <b-modal id="bv-modal-advertencia" hide-header>
        <div class="d-block text-center">
          <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
          <h6>¿Esta seguro que desea eliminar la imagen?</h6>
        </div>
        <template #modal-footer>
          <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia')">
            Cancelar
          </b-button>
          <b-button size="sm" variant="primary" @click="eliminarImagen()">
            Aceptar
          </b-button>
        </template>
      </b-modal>

      <b-toast id="my-toast" :variant="variantToast" solid>
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-baseline">
            <strong>{{textToast}}</strong>
          </div>
        </template>
      </b-toast>
    </b-container>
  </estructura-base>
</template>

<script>
import services from "@/services";
import EstructuraBase from '../components/EstructuraBase.vue';
let config = require("@/config")
export default {
  name: 'CostosMantenimiento',
  components: { EstructuraBase },
  data() {
    return {
      costos: {
        imagen: null,
        url_imagen: null
      },
      imagenBase64: null,      
      variantToast: "",
      textToast: "",
    }
  },
  created() {
    this.obtenerImagen();
  },
  methods: {
    getBase64() {
      let self = this;
      const tamaniomax = 2; //2mb
      const formato = ".jpeg, .jpg, .png";
      if(this.costos.imagen) {
        var file = self.costos.imagen;
        // SI VIENE ALGO
        var extension = file.type.split("/")[1];
        var tamanioArchivo = file.size;
        var tamanioMax = tamaniomax * 1000000;

        // VALIDAR NOMBRE ARCHIVO
        var patt = new RegExp(/^[A-Za-z0-9\s.,-_ ]+$/g);

        if(!patt.test(file.name)) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", "Revise que el nombre de la imagen no tenga caracteres especiales.");
          return
        }

        //SI SE PIDEN UN FORMATO ESPECIFICO
        var formatos = formato ? formato.split(',') : [];

        //SI SOLO SE ADMITE UN FORMATO Y NO ES CORRECTO
        if (formatos.length == 1 && `.${extension}` !== formato) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El formato permitido es ${formato.replace(/[.]/g,"")}`);
          return
        } else if (formatos.length > 1) {
          //SI SE ADMITEN VARIOS FORMATOS
          var formatoValido;
          formatos.filter(formato => {
            if (`.${extension}` === formato.trim()) {
              return formatoValido = true;
            }
          })

          //SI EL FORMATO DEL ARCHIVO NO COINSIDE CON LOS PERMITIDOS
          if (!formatoValido) {
            self.limpiarInput();
            this.$refs['file-input'].reset();
            self.mostrarToast("warning", `Los formatos permitidos son ${formato.replace(/[.]/g,"")}`);
            return
          }
        }

        //SI TAMAÑO MAYOR A TAMAÑO MAXIMO
        if (tamanioArchivo > tamanioMax) {
          self.limpiarInput();
          this.$refs['file-input'].reset();
          self.mostrarToast("warning", `El peso máximo es de ${tamaniomax}MB`);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(self.costos.imagen);
        reader.onload = (loadEvent) => {
          self.guardarImgen(loadEvent.target.result);
        };
      }      
    },
    guardarImgen(imagen) {
      let self = this;
      if(imagen) {
        console.log("entro")
        // self.obtenerImagen();
        services.GuardarImagenCostos({
          imagen_base64: imagen
        })
        .then((response) => {
          self.costos = { imagen: null, url_imagen: null };
          if (response.IsValid) {
            self.mostrarToast("success", response.Message);            
            self.obtenerImagen();
          } else {
            self.mostrarToast("warning", response.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
        });
      } else {
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      }
    },
    obtenerImagen() {
      let self = this;
      services.ObtenerImagenCostos({
      })
      .then((response) => {
        if (response.IsValid) {
          if(response.Data)
            self.costos.url_imagen = `${config.API_URL}${response.Data}`;

        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },    
    modalEliminar() {
      this.$bvModal.show('bv-modal-advertencia');
    },
    eliminarImagen() {
      let self = this;
      services.EliminarImagenCostos({
      })
      .then((response) => {
        if (response.IsValid) {
          self.mostrarToast("success", response.Message);
          self.costos = { imagen: null, url_imagen: null };
          this.$bvModal.hide('bv-modal-advertencia');
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    limpiarInput() {
      this.costos.imagen = null;
      this.imagenBase64 = null;
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('my-toast');
    }
  }
}
</script>

<style scoped>
  .container-form { width: 400px; margin-top: 30vh; }
  .contenedor-imagen { position: relative; }
  .contenedor-imagen img { max-width: 450px; }
  .boton-eliminar-imagen {
    color: #dc3545;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
</style>
