<template>
  <div>
    <b-card-body>
      <!-- agregar nueva modelo -->
      <b-row>
        <b-col>
          <h5 class="text-left">{{titulo}}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12">
          <b-input-group class="mb-2">
            <b-form-input placeholder="Nombre" v-model="modeloSeleccionado.descripcion"></b-form-input>
          </b-input-group>
        </b-col>
        
        <b-col md="12">
          <b-input-group class="mb-5 mt-3" style="justify-content: flex-end;">
            <b-button size="sm" variant="outline-danger" @click="limpiarModelo()" class="mr-1">
              Limpiar
            </b-button>
            <b-button size="sm" @click="guardarModelo()" class="mr-1">
              Guardar
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
      
      <b-table
        small
        stacked="md"
        :items="listaModelos"
        :fields="fields"
        :head-variant="'dark'"
        class="tabla-Modelos"
      >
        <template #cell(fecha)="row">
          {{ row.item.fecha | moment("ll") }}
        </template>
        <template #cell(accion)="row">
          <b-button
              size="sm"
              @click="editarModelo(row.item, row.index, $event.target)"
              class="mr-1 btn-editar"
              variant="light"
            >
            <i class="fas fa-pencil-alt"></i>
          </b-button>
          <b-button
            size="sm"
            @click="modalEliminarModelo(row.item, row.index, $event.target)"
            variant="light"
            class="btn-eliminar"
          >
            <i class="fas fa-trash-alt"></i>
          </b-button>
        </template>
      </b-table>
      <div v-if="!listaModelos.length">No hay registros para mostrar</div>
    </b-card-body>
    <b-modal id="bv-modal-advertencia-modelos" hide-header>
      <div class="d-block text-center">
        <i class="fas fa-exclamation-circle text-warning icon-warning"></i>
        <h6>¿Esta seguro que desea eliminar el registro?</h6>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('bv-modal-advertencia-modelos')">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="eliminarModelo()">
          Aceptar
        </b-button>
      </template>
    </b-modal>

    <b-toast id="toast-Modelos" :variant="variantToast" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong>{{textToast}}</strong>
        </div>
      </template>
    </b-toast>
  </div>
</template>

<script>
import services from "@/services";
export default {
  name: "CatModelos",
  data() {
    return {
      titulo: "Agregar Modelo",
      listaModelos: [],
      modeloSeleccionado: {},
      variantToast: "",
      textToast: "",
      fields: [
        {
          key: "descripcion",
          label: "Nombre",
        },

        { key: "accion", label: "Acciones" },
      ]
    };
  },
  created() {
    this.obtenerlistaModelos();
  },
  methods: {
    guardarModelo() {
      if(!this.modeloSeleccionado.descripcion){
        this.mostrarToast("warning", "El nombre es requerido.");
        return;
      }

      if(this.modeloSeleccionado.id) {
        this.guardarActualizarModelo("EditarModelo");
      } else {
        this.guardarActualizarModelo("GuardarModelo");
      }
      
    },
    guardarActualizarModelo(servicio) {
      let self = this;
      services[servicio](self.modeloSeleccionado)
      .then((response) => {
        if (response.IsValid) {
          self.obtenerlistaModelos();
          self.modeloSeleccionado = {};
          self.titulo = "Agregar Modelo";
          self.mostrarToast("success", response.Message);
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    editarModelo(modelo) {
      this.titulo = "Editar Modelo";
      this.modeloSeleccionado = {...modelo};
    },
    obtenerlistaModelos() {
      let self = this;
      services.ObtenerListaModelos({
      })
      .then((response) => {
        if (response.IsValid) {
          self.listaModelos = response.Data;
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    modalEliminarModelo(modelo) {
      this.modeloEliminar = {...modelo};
      this.$bvModal.show('bv-modal-advertencia-modelos');
    },
    eliminarModelo() {
      let self = this;
      services.EliminarModelo({
        id: self.modeloEliminar.id
      })
      .then((response) => {        
        this.$bvModal.hide('bv-modal-advertencia-modelos');
        if (response.IsValid) {
          self.modeloEliminar = {};
          self.mostrarToast("success", response.Message);
          self.obtenerlistaModelos();
        } else {
          self.mostrarToast("warning", response.Message);
        }
      })
      .catch((e) => {
        console.log(e);
        self.mostrarToast("warning", "Ocurrio un error, inténtelo de nuevo más tarde.");
      });
    },
    limpiarModelo() {
      this.titulo = "Agregar modelo"
      this.modeloSeleccionado = {};
    },
    mostrarToast(variante, mensaje){
      this.variantToast = variante;
      this.textToast = mensaje; 
      this.$bvToast.show('toast-Modelos');
    }
  },
};
</script>

<style scoped>
  .btn-editar { color: #007bff; }
  .btn-eliminar { color: #dc3545; }
</style>
