import axios from "axios";
let config = require("./config")

const API = `${config.API_URL}/api` 

export default {
  ObtenerListaImagenesBanner(data) {
    return axios.post(API+"/Configuracion/ObtenerListaImagenesBanner", data);
  },
  GuardarImagenBanner(data) {
    return axios.post(API+"/Configuracion/GuardarImagenBanner", data);
  },
  EditarImagenBanner(data) {
    return axios.post(API+"/Configuracion/EditarImagenBanner", data);
  },
  EliminarImagenBanner(data) {
    return axios.post(API+"/Configuracion/EliminarImagenBanner", data);
  },
  ObtenerListaNormas(data) {
    return axios.get(API+"/Modelos/ObtenerListaNormas", data);
  },
  ObtenerListaModelos(data) {
    return axios.get(API+"/Modelos/ObtenerListaModelos", data);
  },
  ObtenerListaSeries(data) {
    return axios.get(API+"/Modelos/ObtenerListaSeries", data);
  },
  ObtenerListaVehiculos(data) {
    return axios.post(API+"/Modelos/ObtenerListaVehiculos", data);
  },
  GuardarVehiculo(data) {
    return axios.post(API+"/Modelos/GuardarVehiculo", data);
  },
  EditarVehiculo(data) {
    return axios.post(API+"/Modelos/EditarVehiculo", data);
  },
  ObtenerVehiculoPorId(data) {
    return axios.post(API+"/Modelos/ObtenerVehiculoPorId", data);
  },
  EliminarVehiculo(data) {
    return axios.post(API+"/Modelos/EliminarVehiculo", data);
  },
  ObtenerListaVersionesVehiculo(data) {
    return axios.post(API+"/Modelos/ObtenerListaVersionesVehiculo", data);
  },
  GuardarVersionVehiculo(data) {
    return axios.post(API+"/Modelos/GuardarVersionVehiculo", data);
  },
  EditarVersionVehiculo(data) {
    return axios.post(API+"/Modelos/EditarVersionVehiculo", data);
  },
  EliminarVersionVehiculo(data) {
    return axios.post(API+"/Modelos/EliminarVersionVehiculo", data);
  },
  ObtenerImagenesVehiculoPorId(data) {
    return axios.post(API+"/Modelos/ObtenerImagenesVehiculoPorId", data);
  },
  GuardarImagenesVehiculo(data) {
    return axios.post(API+"/Modelos/GuardarImagenesVehiculo", data);
  },
  EditarImagenVehiculo(data) {
    return axios.post(API+"/Modelos/EditarImagenVehiculo", data);
  },
  EliminarImagenVehiculo(data) {
    return axios.post(API+"/Modelos/EliminarImagenVehiculo", data);
  },
  ObtenerImagenCostos(data) {
    return axios.get(API+"/Configuracion/ObtenerImagenCostos", data);
  },
  GuardarImagenCostos(data) {
    return axios.post(API+"/Configuracion/GuardarImagenCostos", data);
  },
  EliminarImagenCostos(data) {
    return axios.post(API+"/Configuracion/EliminarImagenCostos", data);
  },
  ObtenerListaPuntosRefacciones(data) {
    return axios.post(API+"/Refacciones/ObtenerListaPuntosRefacciones", data);
  },
  GuardarPuntoRefaccion(data) {
    return axios.post(API+"/Refacciones/GuardarPuntoRefaccion", data);
  },
  EditarPuntoRefaccion(data) {
    return axios.post(API+"/Refacciones/EditarPuntoRefaccion", data);
  },
  EliminarPuntoRefaccion(data) {
    return axios.post(API+"/Refacciones/EliminarPuntoRefaccion", data);
  },
  ObtenerListaTecnicos(data) {
    return axios.post(API+"/Tecnicos/ObtenerListaTecnicos", data);
  },
  GuardarTecnico(data) {
    return axios.post(API+"/Tecnicos/GuardarTecnico", data);
  },
  EditarTecnico(data) {
    return axios.post(API+"/Tecnicos/EditarTecnico", data);
  },
  EliminarTecnico(data) {
    return axios.post(API+"/Tecnicos/EliminarTecnico", data);
  },
  ObtenerListaCursos(data) {
    return axios.post(API+"/Cursos/ObtenerListaCursos", data);
  },
  GuardarCurso(data) {
    return axios.post(API+"/Cursos/GuardarCurso", data);
  },
  EditarCurso(data) {
    return axios.post(API+"/Cursos/EditarCurso", data);
  },
  EliminarCurso(data) {
    return axios.post(API+"/Cursos/EliminarCurso", data);
  },
  ObtenerConfiguracionGeneral(data) {
    return axios.get(API+"/Configuracion/ObtenerConfiguracionGeneral", data);
  },
  GuardarConfiguracionGeneral(data) {
    return axios.post(API+"/Configuracion/GuardarConfiguracionGeneral", data);
  },
  login(data) {
    return axios.post(API+"/Accesos/Login", data);
  },
  GuardarNorma(data) {
    return axios.post(API+"/Modelos/GuardarNorma", data);
  },
  EditarNorma(data) {
    return axios.post(API+"/Modelos/EditarNorma", data);
  },
  EliminarNorma(data) {
    return axios.post(API+"/Modelos/EliminarNorma", data);
  },
  GuardarSerie(data) {
    return axios.post(API+"/Modelos/GuardarSerie", data);
  },
  EditarSerie(data) {
    return axios.post(API+"/Modelos/EditarSerie", data);
  },
  EliminarSerie(data) {
    return axios.post(API+"/Modelos/EliminarSerie", data);
  },
  GuardarModelo(data) {
    return axios.post(API+"/Modelos/GuardarModelo", data);
  },
  EditarModelo(data) {
    return axios.post(API+"/Modelos/EditarModelo", data);
  },
  EliminarModelo(data) {
    return axios.post(API+"/Modelos/EliminarModelo", data);
  },
  olvidasteContrasena(data) {
    return axios.post(API+"/Accesos/olvidasteContrasena", data);
  },
  cambiarContrasena(data) {
    return axios.post(API+"/Accesos/CambiarContrasena", data);
  },
};