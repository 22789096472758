<template>
  <div>
    <!-- <b-button v-b-toggle.sidebar-no-header>Toggle Sidebar</b-button> -->
    <b-sidebar
      id="sidebar-no-header"
      :visible="visible"
      bg-variant="dark"
      header-close
      aria-labelledby="sidebar-no-header-title"
      no-header
      shadow
      no-close-on-esc
      no-close-on-backdrop
      no-close-on-route-change
      sidebar-class="navbar-blue-hino"
    >
      <nav id="sidebar" class="navbar-dark text-left">
        <div class="sidebar-header text-center">
          <h3>
            <b-img :src="require('@/assets/images/logo.png')" width="45" height="30" alt="Logo Hino"></b-img>
            <a href="/home" class="w-auto h-auto m-0 sidebar-header navbar-brand ml-2">HINO</a>
          </h3>
          <button type="button" v-if="btnCloseVisible" aria-label="Close" v-b-toggle.sidebar-no-header class="close text-white close-sidebar-hino">
            <i class="mr-2 fas fa-times icono-close"></i>
          </button>
        </div>
        <ul class="navbar-nav list-unstyled components">
          <li class="nav-item">
            <router-link to="/banner" class="nav-link pl-2 pr-2">
              <i class="mr-2 far fa-images"></i>
              Banner
            </router-link>            
          </li>
          <li class="nav-item">
            <a
              href="#modelos"
              data-toggle="collapse"
              aria-haspopup="false"
              aria-expanded="false"
              class="nav-link dropdown-toggle pl-2 pr-2 collapsed"
              >
              <i class="mr-2 fas fa-truck"></i>
              Modelos
            </a>
            <ul            
              id="modelos"
              class="navbar-nav list-unstyled collapse navbar-sub-menu pl-3"
            >
              <li class="nav-item">
                <router-link to="/modelos" class="nav-link pl-2 pr-2">
                  <i class="mr-2 fas fa-list"></i>
                  Lista modelos
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/catalogos" class="nav-link pl-2 pr-2">
                  <i class="mr-2 fas fa-list-ul"></i>
                  Catálogos
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a
              href="#soporteTotal"
              data-toggle="collapse"
              aria-haspopup="false"
              aria-expanded="false"
              class="nav-link dropdown-toggle pl-2 pr-2 collapsed"
              >
              <i class="mr-2 fas fa-headset"></i>
              Soporte total
            </a>
            <ul            
              id="soporteTotal"
              class="navbar-nav list-unstyled collapse navbar-sub-menu pl-3"
            >
              <li class="nav-item">
                <router-link to="/costos-mantenimiento" class="nav-link pl-2 pr-2">
                  <i class="mr-2 fas fa-money-bill-wave"></i>
                  Costos de mantenimiento
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/refacciones" class="nav-link pl-2 pr-2">
                  <i class="mr-2 fas fa-map-marked-alt"></i>
                  Refacciones
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link to="/super-tecnico" class="nav-link pl-2 pr-2">
              <i class="mr-2 fas fa-user-cog"></i>
              Super Técnico
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/cursos-ecodrive" class="nav-link pl-2 pr-2">
              <i class="mr-2 fas fa-chalkboard-teacher"></i>
              Cursos Ecodrive
            </router-link>
          </li>
          <li class="nav-item">
            <a
              href="#configuracion"
              data-toggle="collapse"
              aria-haspopup="false"
              aria-expanded="false"
              class="nav-link dropdown-toggle pl-2 pr-2 collapsed"
              >
              <i class="mr-2 fas fa-cog"></i>
              Configuración
            </a>
            <ul            
              id="configuracion"
              class="navbar-nav list-unstyled collapse navbar-sub-menu pl-3"
            >
              <li class="nav-item">
                <router-link to="/configuracion-general" class="nav-link pl-2 pr-2">
                  <i class="mr-2 fas fa-cog"></i>
                  General
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/cambio-contrasena" class="nav-link pl-2 pr-2">
                  <i class="mr-2 fas fa-key"></i>
                  Cambiar Contraseña
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    msg: String,
  },
  data() {
    return {
      visible: true,
      btnCloseVisible: false
    };
  },
  created() {
    this.mostrarOcultarSidebar();
  },
  mounted() {
    let self = this;
    window.addEventListener('resize', () => {
      self.mostrarOcultarSidebar();
    })
  },
  methods: {
    mostrarOcultarSidebar() {
      if(window.innerWidth < 1000) {
        this.visible = false;
        this.btnCloseVisible = true;
      } else {
        this.visible = true;
        this.btnCloseVisible = false;
      }
    }
  }
};
</script>

<style>
  .b-sidebar {
    width: 250px !important;
  }

  .navbar-blue-hino .b-sidebar-body { background-color: #1e293b !important;}
  .navbar-blue-hino .sidebar-header { 
    background-color: #263248; 
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5rem 1rem, rgba(0, 0, 0, 0.1) 0px -1px 0px inset; 
    height: 50px;
  }
  .navbar-sub-menu { background-color: #1a2133; }

  .close-sidebar-hino {
    position: absolute;
    top: 0;
    right: 8px;
    outline: none !important;
  }

  .close-sidebar-hino .icono-close { font-size: 18px; }

  /* @media (max-width: 1000px) {
    .b-sidebar { display: none;}
  } */
</style>
