<template>
  <div id="app">
    <Loading/>
    <router-view/>
    <!-- <sidebar-menu :menu="menu" :width="width" :widthCollapsed="widthCollapsed">
    </sidebar-menu>
    <router-view/> -->
  </div>
</template>

<script>
import Loading from './components/Loading.vue'

export default {
  name: 'App',
  components: {
    Loading
  },
  data() {
    return {
    }
  },
  created() {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body { overflow-y: hidden; }
</style>