<template>
  <div>
    <Sidebar ></Sidebar>
    <div class="contenedor-general">
      <b-navbar variant="light" sticky class="hino-navbar">
        <b-button class="btn-close-sidebar-app" variant="outline-light" v-b-toggle.sidebar-no-header><i class="fas fa-bars text-dark"></i></b-button>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{correousuario}}</em>
            </template>
            <b-dropdown-item href="#" @click="cerrarSesion()">Cerrar Sesión</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>

      <!-- contenido paginas -->
      <slot></slot>
    </div>
  </div>
</template>


<script>
  import { mapState } from 'vuex';
  import Sidebar from '../components/Sidebar.vue'
  export default {
    name: "EstructuraBase",
    components: {
      Sidebar
    },
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
      ...mapState(['correousuario'])
    },
    mounted() {},
    methods: {
      cerrarSesion() {
        this.$store.dispatch("cerrarSesion");
        this.$router.push("/");
      }
    },
  };
</script>

<style>
  .hino-navbar {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5rem 1rem, rgba(0, 0, 0, 0.1) 0px -1px 0px inset;
    height: 50px;
  }

  .contenedor-general {
    background-color: #f7f7f7;
    margin-left: 250px;
    width: calc(100vw - 250px);
    height: 100vh;
    overflow-y: auto;
  }

  .btn-close-sidebar-app { display: none !important; }

  .toast-body { display: none !important; }

  @media (max-width: 1000px) {
    .contenedor-general { margin-left: 0; width: 100%; }
    .btn-close-sidebar-app { display: block !important; }
  }
</style>
